
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    course: Object
  },
  setup(props) {
    function viewCourse() {
      console.log(111);
    }
    function backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    function filterName(list: Array<any>) {
      const set = new Set();
      return list.filter(item => {
        if (set.has(item.teacherId)) {
          return false;
        } else {
          set.add(item.teacherId);
          return true;
        }
      });
    }
    return {
      viewCourse,
      dateFormat,
      backTop,
      filterName
    };
  }
});
